import React, { useContext } from "react"
import styled from "styled-components"
import ReportContext from "../../context/report.context"

const Aside = styled.aside`
  min-width: 250px;
  max-width: 300px;
  border-left: 1px solid #B5B5B5;

  h2 {
    font-size: 24px;
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: flex;
      flex-direction: row;
      padding: 1rem;
      margin: 0;
      font-family: "Eina01-Bold",sans-serif;
      border-bottom: 1px solid #B5B5B5;
      &:last-of-type {
        border-bottom: none;
      }
      &:first-of-type {
        border-top: 1px solid #B5B5B5;
      }
      &.active {
        background: #001014;
        color: #fff;
        span:first-of-type {
          color: #00FFA8;
        }
        
      }
      
  
    }
    span:first-of-type {
      font-size: 31px;
      line-height: 3rem;
      margin-right: .8rem;
      margin-bottom: 10px;
      line-height: 1;
      display: block;
      color: #6A00FF;
      font-family: "Eina01-Regular",sans-serif;
    }
  }
  &.White ul li.active {
    background: #6A00FF!important;
  
  }
`

const Sticky = styled.div`
  position: sticky;
  top: 1rem;
  height: auto;
`

interface IProps {
  currentArticle: string
}

const Sidebar = ({ currentArticle }: IProps) => {
  const { report } = useContext(ReportContext)

  return (
    <Aside>
      <Sticky>
        {report ? (
          <React.Fragment>
            <h2>{report.title}</h2>
            <nav>
              <ul>
                {report.articles.map((article: any, index: number) => (
                  <li
                    key={index}
                    className={
                      currentArticle === article.slug ? "active" : undefined
                    }
                  >
                    <a href={`/${report.slug}#${article.slug}`}>
                      <span>{index + 1}</span>
                      <span>{article.title}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </React.Fragment>
        ) : (
          "Loading"
        )}
      </Sticky>
    </Aside>
  )
}

export default Sidebar
