import React from "react"
import styled from "styled-components"

import Animate from "../ui/animate-in"

const ShortArticle = styled.div`
  background: ${props => props.theme.colors.primary};
  color: #fff;
  margin: 0 -2rem;
  padding: 2rem 2rem;
  border-top: 5px solid #000;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin: 0 0 0 -4rem;
  }

  h2 {
  
  }
  p {
   
    line-height: 1.7;
  }
  img {
    width: 300px;
    max-width: 100%;
  }
  > .full, > .wide {
    max-width: 100%!important;
    margin-left: 0!important;
    background
    box-sizing: border-box;
    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
      padding: 0 1rem;
    }
  }
  > .full, > .wide {
    background: transparent;
  }
  &.Dark {
    color: #001014;
    
  }
`

interface IProps {
  title: string
  background: string
  text: string
  children: React.ReactNode
}

const BoxOut = ({ title, children, background, text }: IProps) => {
  return (
    <div className="clearfix">
      <ShortArticle className={`${text} boxout`} style={{backgroundColor: `${background}`, color: `${text}`}}>
        <Animate>
          <h2>{title}</h2>
        </Animate>
        {children}
      </ShortArticle>
    </div>

  )
}

export default BoxOut
