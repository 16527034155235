import React from "react"
import styled from "styled-components"
import { animated } from "react-spring"
import Logo from "../assets/logo.svg"

import Navigation from "./menu"

declare global {
  namespace JSX {
      interface IntrinsicElements {
          'navcta': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      }
  }
}

const CloseButton = ({ handleToggle }: any) => {
  const Close = styled.div`
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 2rem;
    right: 1rem;
    cursor: pointer;
  `

  const Bar = styled.div`
    width: 100%;
    height: 2px;
    background: ${props => props.theme.colors.secondary};
    &:first-of-type {
      transform: rotate(-45deg);
    }
    &:last-of-type {
      transform: rotate(45deg);
    }
  `
  

  return (
    <Close onClick={handleToggle}>
      <Bar />
      <Bar />
    </Close>
  )
}

const MobileMenu = ({ handleToggle, style }: any) => {
  const Wrapper = styled(animated.div)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${(props) => props.theme.colors.black};
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2000;
    overflow: scroll;
    svg {
      width: 90px;
      margin: -5rem 0 2rem 1.75rem;
    }
    ul {
      padding: 0;
    }

    li {
      display: block;
      text-align: left;
    }
    a {
      
      font-size: 30px;
        line-height: 62px;
      @media screen and (min-width: ${(props) =>
        props.theme.breakpoints.tablet}) {
        font-size: 30px;
        line-height: 62px;
      }
      
      @media screen and (min-width: ${(props) =>
        props.theme.breakpoints.phone}) {
        // font-size: 25px;
        // line-height: 1.4;
      }
      color: ${(props) => props.theme.colors.light};
      &.active {
        color: ${(props) => props.theme.colors.light};
        //border-color: ${(props) => props.theme.colors.light};
      }
    }
    a.grow {
      position: relative;
    }
    a.grow:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -4px;
      left: 0;
      background-color: ${(props) => props.theme.colors.secondary};
      visibility: hidden;
      width: 0;
      transition: all 0.2s ease-in-out;
    }
    a.grow:hover:before {
      visibility: visible;
      width: 100%;
    }
    a.grow.active:before {
      visibility: visible;
      width: 100%;
    }

  `;
  const NavCta = styled.div`
    border-top: 1px solid ${(props) => props.theme.colors.white};
    padding: 1.75rem;
    font-size: 16px;
    color: ${(props) => props.theme.colors.white};
    p {
      margin: 0;
    }
    a {
      color: ${(props) => props.theme.colors.secondary};
      font-size: 16px;
      line-height: 1;
    }
  `;

  // useBodyScrollLock()
  return (
    <Wrapper style={style} onClick={handleToggle}>
      {/* <Logo /> */}
      <CloseButton handleToggle={handleToggle} />
      <Navigation />
      {/* <NavCta>
        <p>Let’s solve B2B marketing.</p>
        <a href="mailto:contact@alan-agency.com">contact@alan-agency.com</a>
      </NavCta> */}
    </Wrapper>
  );
}

export default MobileMenu
