import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Navigation = styled.ul`
  list-style: none;
  padding: 0;
  margin: -1rem 0 0;
  a {
    font-size: 14px;
    font-family: ${(props) => props.theme.fontRegular};
    line-height: 1;
    color: ${(props) => props.theme.colors.light};
    text-decoration: none;
    margin: 0.25rem;
    border-bottom: 1px solid transparent;
    transition: 0.2s;
    
    //&:hover,
    &[aria-current="page"],
    &.active {
      //border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
    }
    
  }
  a.grow {
    position: relative;
  }
  a.grow:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -4px;
    left: 0;
    background-color: ${(props) => props.theme.colors.secondary};
    visibility: hidden;
    width: 0;
    transition: all 0.2s ease-in-out;
  }
  a.grow:hover:before {
    visibility: visible;
    width: 100%
  }
  a.grow.active:before {
    visibility: visible;
    width: 100%
  }
  li {
    display: inline-block;
    margin: 1rem 1.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Menu = () => {
  return (
    <nav>
      <Navigation>
        <li>
          <a href="//www.alan-agency.com/#work" className="grow" target="_blank">
            Work
          </a>
        </li>
        <li>
          <a href="//www.alan-agency.com/about" className="grow" target="_blank">
            About
          </a>
        </li>
        <li>
          <a href="//www.alan-agency.com/approach" className="grow" target="_blank">
            Approach
          </a>
        </li>
        <li>
          <a href="//www.alan-agency.com/insight" className="grow" target="_blank">
            Truth
          </a>
        </li>
        <li>
          <a href="//www.alan-agency.com/contact" className="grow" target="_blank">
            Contact
          </a>
        </li>
      </Navigation>
    </nav>
  )
}

export default Menu
