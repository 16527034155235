import React from "react"
import styled from "styled-components"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

// import ReportContext from "../../context/report.context"
import Container from "../layout/container"
import Sidebar from "../article/sidebar"
import StandFirst from "../article/standfirst"
import Content from "../article/content"
import Blockquote from "../article/quote"
import Animate from "../ui/animate-in"
import Hype from "../ui/hype"
import { Player } from '@lottiefiles/react-lottie-player';
import AIAnim from "../article/aianim"
import Iframe from 'react-iframe'



const Header = styled.header`
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
`

const Headline = styled.div`
  // margin: 0 0 1rem;
  // max-width: 600px;

  h1 {
    font-size: 36px;
    line-height: 1.1;
    // padding: 0.25rem 0;
    span {
      // background: ${props => props.theme.colors.primary};
      // color: #fff;
      // display: inline;
      // padding: 0.5rem;
      // box-decoration-break: clone;
      // -webkit-box-decoration-break: clone;
    }
    margin-top: 2rem;
    @media screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
      font-size: 48px;
      
    }
  }
`

const Chart = styled.div`
  width: 100%!important;
  font-size: 24px;
  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    width: calc(100% + 4rem)!important;
    margin-left: -4rem;
  }
  > div {
    margin: 0 auto;
  }
`

const Infographics = styled.div`
  flex-grow: 1;
  @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 0 1rem 0 0rem;
  }
`

interface IProps {
  title: string
  slug: string
  reportTitle: string
  reportSlug: string
  standfirst: string
  header: string
  headerType: string
  backgroundColor: string
  textColor: string
  content: {
    json: any
  }
  allArticles: {
    title: string
    slug: string
  }[]
}

const Infographic = ({ ...props }: IProps) => {
  const options: any = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => <p>{children}</p>,
      [BLOCKS.QUOTE]: (_node: any, children: any) => (
        <Blockquote>{children}</Blockquote>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (_node: any) => {
        const entry = _node.data.target

        if (
          entry.sys.type === "Entry" &&
          entry.sys.contentType.sys.id === "charts"
        ) {
          return (
            <Chart>
              <Hype animationName={_node.data.target.fields.hypeId["en-GB"]} />
            </Chart>
          )
        } else if (
          entry.sys.type === "Entry" &&
          entry.sys.contentType.sys.id === "lottieAnimation"
        ) {
          return (
            <Chart>
              <Player autoplay loop src={_node.data.target.fields.lottieAnimation["en-GB"].fields.file["en-GB"].url} style={{ width: '100%' }}></Player>
            </Chart>
          )          
        } else if (
          entry.sys.type === "Entry" &&
          entry.sys.contentType.sys.id === "aiAnimation"
        ) {
          function onLoad() {
            var frames = document.getElementsByTagName('iframe');
            window.addEventListener('message', function(event) {
                for (var i = 0; i < frames.length; i++) {
                    if (frames[i].contentWindow === event.source) {
                      frames[i].style.height = event.data.data.height + "px";
                      // console.log(event.data.data.height)
                        break;
                    }
                }
            });
          
          }
          return (
            <AIAnim format={entry.fields.aiFormat["en-GB"]}>
              <Iframe url={entry.fields.aiLink["en-GB"]}
                width="100%"
                height="100%"
                onLoad={onLoad}
                id={entry.sys.id}
                className=""
                display="block"
                position="relative"/>
              
              
            </AIAnim>
          )          
        }
      },
    },
  }

  const Section = styled.article`
    > div > div {
      border-left: none;
    }
    background: ${props.backgroundColor};
    color: ${props.textColor};
    iframe, .full, .wide {
      background: ${props.backgroundColor};
      padding-right: 0;
    }

      .wide:before {
        background: linear-gradient(0deg, ${props.backgroundColor}, transparent);
      }
      .wide:after {
        background: linear-gradient(180deg, ${props.backgroundColor}, transparent);
      }
    
  `

  // const { report } = useContext(ReportContext)
  return (
    <Section id={props.slug}>
      <Header className="headerhype">
        <Container>
          <Animate>
            <Headline>
              <h1>
                <span>{props.title}</span>
              </h1>
            </Headline>
          </Animate>
          <Animate>
            <StandFirst>{props.standfirst}</StandFirst>
          </Animate>
          {props.headerType === 'hype'
            ? <Hype animationName={props.header} />
            : <Player autoplay loop src={props.header} style={{ width: '100%' }}></Player>
          }          
        </Container>
      </Header>
      <Container>
        <Content>
        
          <Infographics className="bodycopy">
            {documentToReactComponents(props.content.json, options)}
          </Infographics>
         
          <Sidebar currentArticle={props.slug}/>
        </Content>
      </Container>
    </Section>
  )
}

export default Infographic
