import React from "react"
import styled from "styled-components"

const AIChart = styled.div`
  margin: 1rem 1rem 1rem 0;
  position: relative;
  background: #fff;
  color: #001014;
  padding: 0;
  padding-right: 1rem;
  width: 100%;
  box-sizing: border-box;
 
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5rem;
  iframe {
    margin-bottom: 0;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    float: left;
    margin-left: -3rem;
    width: 385px!important;
    &.full {
      width: calc(100% + 3rem)!important;
      float: none;
    }
    &.wide {
      width: calc(100% + 3rem + 300px + 1rem)!important;
      float: none;
      z-index: 5;
      &:before {
        background: linear-gradient(0deg, white, transparent);
      }
      &:after {
        background: linear-gradient(180deg, white, transparent);
      }
    }
  }
`

interface IProps {
  children: React.ReactNode
  format: string
}

const AIChartWrapper = ({ children, format }: IProps) => {
  return (
    <AIChart className={format === "Full width" ? "full" : format === "Wide" ? "wide" : undefined}>
      {children}
    </AIChart>
  )
}

export default AIChartWrapper
