import React, { useContext } from "react"
import styled from "styled-components"

import Container from "../layout/container"
import Animate from "../ui/animate-in"

import Lines from "../../assets/lines.svg"
import IArticle from "../../interface/article.interface"
import IInfographic from "../../interface/infographic.interface"
import ReportContext from "../../context/report.context"

const Section = styled.section`
  padding: 4rem 0 3rem;
  background: #fff;
  color: #001014;
  h2 {
    font-size: 40px;
    text-align: center;
    letter-spacing: -2px;
    @media screen and (min-width: ${props =>
      props.theme.breakpoints.tablet}) {
        font-size: 60px;
    }
  }
`

const List = styled.ol`
  list-style: none;
  padding: 0;
  margin: 2rem 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    width: 100%;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    a {
      display: flex;
      flex-direction: column;
      text-align: center;
      @media screen and (min-width: ${props =>
          props.theme.breakpoints.tablet}) {
        flex-direction: row;
        text-align: left;
      }
    }
    @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
      width: calc(50% - 1rem);
      &:nth-of-type(2n + 1) {
        margin-right: 1rem;
      }
      &:nth-of-type(2n + 2) {
        margin-left: 1rem;
      }
    }
  }
`

const Bullet = styled.span`
  display: block;

  margin: .5rem auto;
  v
  background: #6A00FF;

  color: #fff;

  font-size: 39px;
  line-height: 80px;
  text-align: center;
  min-width: 86px;
    width: 86px;
    height: 86px;
    background: #6A00FF;
  @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: inline-block;

    margin-right: 1.5rem;
    min-width: 86px;
    width: 86px;
    height: 86px;
    background: #6A00FF;

    color: #fff;


    text-align: center;
    display: block;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    min-width: 86px;
    width: 86px;
    height: 86px;

  }
`

const Text = styled.div`
  padding-top: 0.5rem;
  min-width: calc(100% - 106px);
  h3 {
    font-size: 25px;
    margin-bottom: .8rem;
  }
  p {
    font-size: 14px;
    line-height: 1.4;
  }
`
let pathname = typeof window !== "undefined" ? window.location.pathname : "" ;

console.log(pathname);

let contents;
if (pathname === "/auf-dem-weg-zu-industry-4.0") {
    contents = <h2>Inhalt</h2>;
} else if (pathname === "/assurer-la-transition-vers-lindustrie-4-0"){
    contents = <h2>Contenu</h2>;
} else {
  contents = <h2>Contents</h2>;
}

const TableOfContents = () => {
  const { report } = useContext(ReportContext)

  return (
    <Section id="table-of-contents">
      <Container>
        <Animate>
          {contents}
        </Animate>
        <List>
          {report.articles.map(
            (article: IArticle | IInfographic, index: number) => (
              <li key={index}>
                <a href={`/${report.slug}#${article.slug}`}>
                  <Bullet>{index + 1}</Bullet>
                  <Text>
                    <h3>{article.title}</h3>
                    <p>{article.standFirst.standFirst}</p>
                  </Text>
                </a>
              </li>
            )
          )}
        </List>
      </Container>
    </Section>
  )
}

export default TableOfContents
