import React from "react"
import styled from "styled-components"

const Standfirst = styled.p`
  font-size: 18px;
  line-height: 1.25;
  margin-bottom: 3rem;


  @media print {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
    
    font-size: 25px;
  }
`

interface IProps {
  children: React.ReactNode
}

const StandFirst = ({ children }: IProps) => {
  return <Standfirst>{children}</Standfirst>
}

export default StandFirst
