import React, { useContext } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import styled from "styled-components"

import Logo from "../../assets/logo-dark.svg"
import Lines from "../../assets/lines.svg"

import ReportContext from "../../context/report.context"

const Foot = styled.footer`
  

  > * {
    width: ${props => props.theme.container.width};
    max-width: ${props => props.theme.container.maxWidth};
    margin: 0 auto;
  }
  background: #fff;
  color: #001014;
  &.White svg path {
    fill: white!important;
  }
  h2,
  h3 {

    font-size: 1rem;
    font-weight: bold;
    margin: 0;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
  }

  .sponsored-by {
    margin-bottom: 1rem;
  }
  .footer-logo {
    img {
      width: 80px;
    }
  }
`

const Border = styled(Lines)`
  margin: 2.5rem 0;
`

const Columns = styled.div`
  margin-bottom: 2rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  
`

const Column = styled.div`
  min-width: 288px;
  flex-grow: 1;
  &:not(:last-of-type) {
    margin-right: 1rem;
  }
  &:first-of-type {
    width: 32%;
  }
  svg {
    
    height: 28px;
  }
`

let pathname = typeof window !== "undefined" ? window.location.pathname : "" ;

console.log(pathname);

let contributorTranslate;
if (pathname === "/auf-dem-weg-zu-industry-4.0") {
    contributorTranslate = <strong>Verfasser: </strong>;
} else if (pathname === "/assurer-la-transition-vers-lindustrie-4-0"){
    contributorTranslate = <strong>Contributeurs: </strong>;
} else {
  contributorTranslate = <strong>Contributors: </strong>;
}

const Footer = () => {
  const { report } = useContext(ReportContext)

  const options: any = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => <p>{children}</p>,
    },
  }

  return (
    <Foot>
      <div>
      <Border />
      {report.sponsoredBy && (
        <div >
          <h3 className="sponsored-by">
            {report.sponsoredBy && report.sponsoredBy.sponsoredBy
              ? report.sponsoredBy.sponsoredBy
              : `Publication sponsored by`}
          </h3>
          <a className="footer-logo " href={report.sponsoredBy.link} target="_blank">
            <img  src={report.sponsoredBy.logo.file.url} />
          </a>
        </div>
      )}
      {report.footerText && (
        <Columns>
          {documentToReactComponents(report.footerText.json, options)}
        </Columns>
      )}
      <Wrapper>
        <Column>
          <Logo />
        </Column>
      </Wrapper>
      <Border />
      </div>
      
    </Foot>
  )
}

export default Footer
