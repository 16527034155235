import React from "react"
import styled from "styled-components"

const Article = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-left: 3rem;
    border-left: 1px solid #B5B5B5;
    & > div {
      // border-bottom: 1px solid #B5B5B5;
    }
  }

  // & > div > p:first-of-type::first-letter {
  //   font-size: 4rem;
  //   font-weight: bold;
  //   float: left;
  //   line-height: 3rem;
  //   // color: ${props => props.theme.colors.primary};
  //   margin-right: 0.5rem;
  //   font-family: "sofia-pro";
  //   font-style: normal;
  // }

  p,
  strong,
  h2 {
    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
      padding: 0 1rem;
    }
    
  }

  p {
    font-size: 14px;
    line-height: 1.5;
  }

  // ul, ol {
  //   margin-left: 0;
  //   @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
  //     margin-left: 1rem;
  //   }

  //   list-style-position: inside;
  //   li::marker {
  //     float: left;
  //   }
  //   li::marker, 
    
    
  // }

  ul, ol {
    > * {
      p {
        padding-left: .5rem;
      }
    }
  }

  

  img {
    width: 100%;
  }

  aside {
    display: none;

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
      display: block;
    }
  }
`

interface IProps {
  children: React.ReactNode
}

const Content = ({ children }: IProps) => {
  return <Article>{children}</Article>
}

export default Content
