import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Container from "../layout/container"
import Hype from "../ui/hype"

import LogoSvg from "../../assets/logo.svg"
import ScrollDown from "../../assets/scroll-down.svg"
import ReportContext from "../../context/report.context"
import { Player } from '@lottiefiles/react-lottie-player';
import Header from "../layout/header"

const HomeScreen = styled.section`
  position: relative;
  width: 100vw;
  padding: 2rem 0 6rem;

  h1 {
    width: 100%;
    max-width: 700px;
    color: #fff;
    text-align: center;
    display: block;
    margin: 0 auto 3rem;
    font-size: 38px;
    font-family: "Eina01-Regular", sans-serif;
    font-weight: normal;
    @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 56px;
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  .HYPE_document {
    padding-bottom: 56.25%;
  }
`

const Wrap = styled.div`
  width: 90vh;
  max-width: 100%;
  margin: 0 auto;
  .lf-player-container {
    max-width: 420px;
    margin: 0 auto;
  }
`

const SponsoredText = styled.p`
  text-align: center;
  color: #fff;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: -1.5rem;
`

const Sponsor = styled.img`
  margin: 2rem auto;
  height: 5rem;
  display: block;
  max-width: 300px;
`

const Logo = styled(LogoSvg)`
width: 74px;
height: auto;
display: block;
margin: 1rem auto 2rem;
`

const Scroll = styled(ScrollDown)`
position: absolute;
bottom: 2rem;
left: 50%;
transform: translateX(-50%);
transition: 0.5s;
width: 30px;
&:hover {
  margin-bottom: 0.5rem;
}

`

const Homescreen = () => {
  const { report } = useContext(ReportContext)

  const css = `
    .logo-top path, #scrollDownIcon {
        fill: ${report.headerTextColour };
    }
  `

  let headerAnimation;

  if (report.hypeAnimation.hypeId) {
    headerAnimation = <Hype animationName={report.hypeAnimation.hypeId} />;
  } else if (report.hypeAnimation.lottieAnimation) {
    headerAnimation = <Player autoplay loop src={report.hypeAnimation.lottieAnimation.file.url} style={{ width: '100%' }}></Player>;
  } else {
    headerAnimation = <Img fluid={report.featuredImage.fluid} />
  }

  let pathname = typeof window !== "undefined" ? window.location.pathname : "" ;

  let reportTitle;
  if (pathname === "/the-power-of-provocation") {
    reportTitle = ['The Power', <br />, 'of Provocation'];
  } else {
    reportTitle = report.title
  }

  return (
    <HomeScreen style={{ backgroundColor: report.headerColor, color: report.headerTextColour }}>
      <Container>
        <style>{css}</style>
        <Header />
        <h1 style={{ color: report.headerTextColour }} className="report-title">{reportTitle}</h1>
        <Wrap>
          {headerAnimation} 
        </Wrap>
        <Link to={`/${report.slug}/#table-of-contents`} className="scroll-down">
          <Scroll/>
        </Link>
        {report.sponsoredBy && report.sponsoredBy.sponsoredBy && (
          <SponsoredText style={{ color: report.headerTextColour }}>{report.sponsoredBy.sponsoredBy}</SponsoredText>
        )}
        {report.sponsoredBy && (
          <a href={report.sponsoredBy.link} target="_blank">
            <Sponsor
              src={
                report.sponsoredBy.logoWhite
                  ? report.sponsoredBy.logoWhite.file.url
                  : report.sponsoredBy.logo.file.url
              }
            />
          </a>
        )}
      </Container>
    </HomeScreen>
  )
}

export default Homescreen
