import React from "react"

import styled from "styled-components"

import Quotes from "../../assets/quotes.svg"

const Blockquote = styled.blockquote`
  margin: .8rem 1rem .8rem 0;
  position: relative;
  background: #fff;
  color: #001014;
  border-top: 1px solid #B5B5B5;
  border-bottom: 1px solid #B5B5B5;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5rem;

  svg {
    position: relative;
    top: 0rem;
    left: 1rem;
    padding-bottom: 1rem;
    width: 25px;
  }

  p {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.2;
    
    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
      padding-right: 0.5rem;
    
    }
  }

  p b {
    font-size: 15px;
    font-weight: normal;
    font-family: "Eina01-Bold",sans-serif;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    float: left;
    margin-left: -3rem;
    width: 50%;
  
  }
`

interface IProps {
  children: React.ReactNode
}

const Quote = ({ children }: IProps) => {
  return (
    <Blockquote>
      <Quotes />
      {children}
    </Blockquote>
  )
}

export default Quote
