import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { BLOCKS } from "@contentful/rich-text-types"
import { MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

// import ReportContext from "../../context/report.context"
import Container from "../layout/container"
import Sidebar from "../article/sidebar"
import StandFirst from "../article/standfirst"
import Content from "../article/content"
import BoxOut from "../article/boxout"
import Blockquote from "../article/quote"
import Animate from "../ui/animate-in"
import Hype from "../ui/hype"
import Iframe from 'react-iframe'
import Chart from "../article/chart"
import AIChart from "../article/aianim"


import { Player } from '@lottiefiles/react-lottie-player';

import Lines from "../../assets/lines.svg"

import IArticle from "../../interface/article.interface"

const Header = styled.header`
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {

    padding-bottom: 0;
  }
  .gatsby-image-wrapper {
    position: relative !important;
    top: 0;
    left: 0;
    width: 100%;
    > * {
      padding-bottom: 0!important;
      margin-bottom: 0!important;
    }
    z-index: 1;
    picture img {
      display: block;
      position: relative!important;
      margin-bottom: 0;
    }
  }
`

const Author = styled.strong`
  // font-family: ${props => props.theme.fonts.sofia};
  border-bottom: 1px solid #B5B5B7;
  width: 100%; display: block;
  padding-bottom: .5rem!important;
  margin-bottom: .5rem;
  font-size: 14px;
`

interface IProps extends IArticle {
  reportSlug: string
  reportTitle: string
  allArticles: {
    title: string
    slug: string
  }[]
}

let pathname = typeof window !== "undefined" ? window.location.pathname : "" ;

console.log(pathname);

let commercialFeaturesTranslate;
if (pathname === "/auf-dem-weg-zu-industry-4.0") {
    commercialFeaturesTranslate = "Gesponserter Beitrag";
} else if (pathname === "/assurer-la-transition-vers-lindustrie-4-0"){
    commercialFeaturesTranslate = "Article commercial";
} else {
  commercialFeaturesTranslate = "Commercial feature";
}

const Article = ({ ...props }: IProps) => {
  const advertorial = props.isAdvertorial
    ? {
        fontFamily: `"Eina01-Regular", sans-serif`,
        // marginTop: `-5rem`,
        lineHeight: `1.4`,
      }
    : {
        fontFamily: `"Eina01-Regular", sans-serif`,
        // marginTop: `-3rem`,
        lineHeight: `1.2`,
      }

  const Section = styled.article`
    padding-bottom: 4rem;
    background: #fff;
    color: #001014;
    .boxout {

    }
  `

  const Headline = styled.div`
    // margin: ${advertorial.marginTop} 0 2rem;
    // max-width: 600px;
    // margin-top: 2rem
    h1 {
      font-size: 32px;
      line-height: 1.1;
      // padding: 0.25rem 0;
      margin-top: 2rem;
      span {
        // background: ${props => props.theme.colors.primary};
        // color: #fff;
        // display: inline;
        // padding: 0.5rem;
        // box-decoration-break: clone;
        // -webkit-box-decoration-break: clone;
      }
      @media screen and (min-width: ${props =>
          props.theme.breakpoints.tablet}) {
        font-size: 48px;
        margin-top: 3rem;
      }
    }
  `

  const Feature = styled.span`
    background: #fff;
    padding: 0;
    transform: translateY(100%);
    margin-bottom: 0;
    display: inline-block;
    color: #000;
    font-size: 14px;
    text-transform: uppercase;

    letter-spacing: 2px;

  `

  const Small = styled.small`
    font-size: 0.7rem;
    line-height: 1.25em !important;
  `

  const options: any = {
    renderMark: { [MARKS.CODE]: (embedded: any) => <div dangerouslySetInnerHTML={{ __html: embedded }} /> },
    renderNode: {
      [BLOCKS.QUOTE]: (_node: any, children: any) => (
        <Blockquote>{children}</Blockquote>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (_node: any) => {
        const entry = _node.data.target

        if (
          entry.sys.type === "Entry" &&
          entry.sys.contentType.sys.id === "charts"
        ) {
          return (
            <Chart format={entry.fields.format["en-GB"]}>
              <Hype animationName={entry.fields.hypeId["en-GB"]} />
            </Chart>
          )
        } else if (
          entry.sys.type === "Entry" &&
          entry.sys.contentType.sys.id === "smallPrint"
        ) {
          return entry.fields.text["en-GB"].content.map((c: any, i: number) => (
            <p key={i}>
              <Small>{c.content.map((text: any) => text.value)}</Small>
            </p>
          ))
        } else if (
          entry.sys.type === "Entry" &&
          entry.sys.contentType.sys.id === "lottieAnimation"
        ) {
          return (
            <Chart format={entry.fields.lottieFormat["en-GB"]}>
              <Player autoplay loop src={entry.fields.lottieAnimation["en-GB"].fields.file["en-GB"].url} style={{ width: '100%' }}></Player>
            </Chart>
          )          
        } else if (
          entry.sys.type === "Entry" &&
          entry.sys.contentType.sys.id === "aiAnimation"
        ) {
          function onLoad() {
            var frames = document.getElementsByTagName('iframe');
            window.addEventListener('message', function(event) {
                for (var i = 0; i < frames.length; i++) {
                    if (frames[i].contentWindow === event.source) {
                      frames[i].style.height = event.data.data.height + "px";
                      // console.log(event.data.data.height)
                        break;
                    }
                }
            });
          
          }
          return (
            <AIChart format={entry.fields.aiFormat["en-GB"]}>
              <Iframe url={entry.fields.aiLink["en-GB"]}
                width="100%"
                height="100%"
                onLoad={onLoad}
                id={entry.sys.id}
                className=""
                display="block"
                position="relative"/>
              
              
            </AIChart>
          )          
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (_node: any) => (
        <p>
          <img
            src={_node.data.target.fields.file["en-GB"].url}
            alt={_node.data.target.fields.title["en-GB"]}
          />
        </p>
      ),
    },
  }
  

  // const { report } = useContext(ReportContext)
  return (
    <Section id={props.slug}>
      {props.featuredImage && (
        <Header>
          <Img fluid={props.featuredImage.fluid} />
        </Header>
      )}
      <Container >
        <Animate>
          <Headline>
            {props.isAdvertorial && <Feature>{commercialFeaturesTranslate}</Feature>}
            <h1>
              <span>{props.title}</span>
            </h1>
          </Headline>
        </Animate>
        <Animate>
          <StandFirst>{props.standFirst.standFirst}</StandFirst>
        </Animate>
        <Content>
          <div className="bodycopy">
            {props.author !== null && (
              <Author>{props.author}</Author>
            )}
            {documentToReactComponents(props.copy.json, options)}
            {props.boxOut !== null && (
              <BoxOut title={props.boxOut.title} background={props.boxOut.background} text={props.boxOut.text}>
                {documentToReactComponents(props.boxOut.copy.json, options)}
              </BoxOut>
            )}
          </div>
          <Sidebar currentArticle={props.slug} />
        </Content>
      </Container>
    </Section>
  )
}

export default Article
