import React, { useState } from "react"
import { Link } from "gatsby"
import styled, { withTheme } from "styled-components"
import { useSpring } from "react-spring"

import Menu from "./menu"
import MobileMenu from "./mobile-menu"

import Logo from "../../assets/logo.svg"
//import Hype from "../ui/hype";

interface IToggle {
  handleToggle: any
}

const MobileToggle = ({ handleToggle }: IToggle) => {
  const Toggle = styled.div`
    width: 2.5rem;
    height: 1.5rem;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    cursor: pointer;

    @media only screen and (min-width: 769px) {
      display: none;
    }

    &:before,
    &:after,
    div {
      content: "";
      position: absolute;
      right: 0;
      height: 2px;
      background: ${props => props.theme.colors.secondary};
      display: block;
    }
    &:before {
      width: 100%;
      top: 0;
    }
    &:after {
      width: 100%;
      bottom: 0;
    }

    div {
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  `

  return (
    <Toggle onClick={handleToggle}>
      <div />
    </Toggle>
  )
}

const Header = ({ theme, headerClass }: any) => {
  const [toggleMenu, setToggleMenu] = useState<boolean>(false)

  const handleToggle = () => {
    setToggleMenu(!toggleMenu)
  }

  const navAnimation = useSpring({
    opacity: toggleMenu ? 1 : 0,
    transform: toggleMenu ? `translateX(0)` : `translateX(100%)`,
    config: {
      tension: 450,
    },
  });


  const Container = styled.div`

  `;

  const Head = styled.header`
    max-width: 1200px;
    margin: 0 auto;

    box-sizing: border-box;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: row;
    z-index: 2 !important;
    justify-content: space-between;
    @media only screen and (max-width: ${theme.breakpoints.phone}) {
      padding: 0rem 0 2rem;
    }

    svg {
      fill: $#00ffa8;
      width: 100px;
      transition: 0.2s;
    }
    .HYPE_scene {
      background-color: transparent !important;
    }

    nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: ${theme.breakpoints.tablet}) {
        display: none;
      }
    }
  `;

  return (
    <React.Fragment>
      
        <MobileMenu style={navAnimation} handleToggle={handleToggle} />
     
          <Container className={headerClass}>
          <Head>
            <a href="https://www.alan-agency.com/" aria-label="Home" target="_blank">
              <Logo />
              {/* <Hype /> */}
            </a>
            <Menu />
            <MobileToggle handleToggle={handleToggle} />
          </Head>
          </Container>

    </React.Fragment>
  );
}

export default withTheme(Header)
